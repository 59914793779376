import Footer from 'components/layouts/Footer'
import Page from 'components/Page'
import { GetStaticProps } from 'next'

import ContactUsForm from 'components/home/ContactUsForm'
import Layout from 'components/layouts/Layout'
import { useTranslations } from 'next-intl'
import { ReactElement } from 'react'
import { Button } from 'components/commons/Button'
import { Link } from 'components/commons/Link'

function ContactUs() {

  const t = useTranslations("404")

  return (
    <Page id='not-found' title={t("metadata-title")} description={t("metadata-description")} footer>
      <div className='max-w-4xl mx-auto text-center'>
        <h1 className='text-white text-center text-7xl font-bold mb-12 md:text-10xl md:font-extrabold'>
          {t("title")}
        </h1>
        <Link href='/' >
          <Button variant='primary'>{t("next-button")}</Button>
        </Link>
      </div>
    </Page>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  return {
    props: {
      context,
      messages: (await import(`../lang/${context.locale}.json`)).default
    }
  }
}

ContactUs.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout variant='secondary'>
      {page}
    </Layout>
  )
}

export default ContactUs
